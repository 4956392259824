import React from 'react'

const Facilitiesprop = (props) => {
  return (
      <>
            <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="facilitiesbox">
                     <img src={props.image}/>
                     <h3>{props.title}</h3>
                     <p>{props.desc}</p>
                  </div>
            </div>
      </>
  )
}

export default Facilitiesprop
