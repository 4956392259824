import React, { useEffect, useState } from 'react';
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer' 
import {getPrincipalMessage} from'../Service/Api'
const PrincipalMessage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
 
     <>
       <Header/>
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li> 
            <li> Principal's Message </li>
        </ul>
    </div>
    <div className="innersec">
      <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Principal's Message </h1>
                </div>
            </div>
            {data.length > 0 ? data.map((item) => (
                <div className="clearfix">
                 <img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt=""/>
            
                   <p>{item.message}</p> 
                  <p className="name"> {item.name}<span>  {item.category} </span></p>
          
             
            </div> 
            )) : emptyData.map((item) => (
                <div className="clearfix"> 
                <img src="https://webapi.entab.info/api/image/SBSB/public/Images/principal.jpg" className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt=""/>
                <p> I am happy and delighted to address you all through school online platform. Education at St. Basil School is designed to meet the needs of each individual student. It is our foremost priority to bring the best of your children, but we are not enough. You, the parents, must play a responsible key role in shaping children. <br/>
                Your words of encouragement, your interest in your children’s work, and your presence at bringing them to school atleast weekly once are vital! Your involvement, concern will show your children that you value their education. Besides, our staff will remain as lifelong learners with open heart and mind to accept and apply new challenges and strategies to make sure that the children are always the ultimate winner in the school campus. <br/>Let’s work together, to make your children as responsible citizens to reach new heights in order to make the world a better place to live.</p>
                 <p className="name"> Sr Regi Joseph <span>  Principal </span>
              
                </p> 
                </div> 
                ))}
        </div>
    </div>
       <Footer/>
     </>
  )
}

export default PrincipalMessage
