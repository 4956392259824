import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Component/Header'
import Footer from'../Component/Footer'
const ContactUs = () => {
  return (
    <>
    <Header/>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li> 
     <li> Contact Us </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Contact Us </h1>
         </div>
     </div>
     <div className="enquiryform">
     <div className="enquiryformimg">
           <div className="locationbox">
               <div className="locationdiv">
                    <div className="locationicon">
                        <i className="bi bi-geo-alt"></i>
                    </div>
                    <div className="locationdetails">
                        <h5>address</h5>
                        <p><Link to="https://maps.app.goo.gl/H5tnvYd2Rse8zabG7" target="_blank">  St. Basil High School,
                        Khirighat, Basil Nagar,
                        Basti - 272002
                        Uttar Pradesh.</Link></p>
                    </div>
               </div>
               <div className="locationdiv">
                    <div className="locationicon">
                        <i className="bi bi-telephone"></i>
                    </div>
                    <div className="locationdetails">
                        <h5>Phone</h5>
                        <p><Link to="tel:+919452383085">  +91 9452383085 </Link> </p>
                    </div>
               </div>
               <div className="locationdiv">
                    <div className="locationicon">
                        <i className="bi bi-envelope"></i>
                    </div>
                    <div className="locationdetails">
                        <h5>Email</h5>
                        <p><Link to="mailto:stbasilbasti@gmail.com">  stbasilbasti@gmail.com</Link></p>
                    </div>
               </div>
           </div>
     </div>
     <div className="contactdiv">
         <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1277242.2614356831!2d82.743986!3d26.805442!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3990d005de2926af%3A0xb8a774303c1ddfbc!2sSt.%20Basil&#39;s%20School!5e1!3m2!1sen!2sin!4v1719901978304!5m2!1sen!2sin" width="100%" height="470" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
     </div>
 </div>
</div>
</div>
<Footer/>
    </>
  )
}

export default ContactUs
