import Home from'./Pages/Home'
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import TopperICS from './Pages/TopperICS';
import TopperICSE from './Pages/TopperICSE';
import Admission from'./Pages/Admission'
import RegistrationAdmission from'./Pages/RegistrationAdmission'
import Curriculum from'./Pages/Curriculum'
import ContactUs from'./Pages/ContactUs'
import Gallery from'./Pages/Gallery'
import News from'./Pages/News'
import Events from'./Pages/Events'
import SubGallery from'./Pages/SubGallery'
import PrincipalMessage from'./Pages/PrincipalMessage'
 import Tc from'./Pages/Tc'
 import ErpSoftware from'./Pages/ErpSoftware'
 import Pphelp from'./Pages/Pphelp'
import { useEffect } from 'react';
function App() { 


  return (
      <>
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="RegistrationAdmission" element={<RegistrationAdmission/>} />
          <Route path="Admission" element={<Admission/>} />
          <Route path="TopperICS" element={<TopperICS/>} />
          <Route path="TopperICSE" element={<TopperICSE/>} />
          <Route path="Curriculum" element={<Curriculum/>} />
          <Route path="ContactUs" element={<ContactUs/>} />
          <Route path="Gallery" element={<Gallery/>} />
          <Route path="News" element={<News/>} />
          <Route path="Events" element={<Events/>} />
           <Route path="SubGallery" element={<SubGallery/>} />
           <Route path="PrincipalMessage" element={<PrincipalMessage/>} />
            <Route path="Tc" element={<Tc/>} />
              <Route path="ErpSoftware" element={<ErpSoftware/>} />
               <Route path="Pphelp" element={<Pphelp/>} />
      </Routes>
     </BrowserRouter>
      </>
  )
}

export default App;
