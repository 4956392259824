import React, { useEffect, useState } from 'react'
import Slider from "react-slick"; 
import {getGallery} from'../Service/Api'
const Gallery = () => {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(null);

   useEffect(() => {
       const fetchData = async () =>{
              try{
                 const galleryData = await getGallery();
                 setData(galleryData) ;  
              }catch(error){
               setError(error);
              }finally{
               setLoading(false)
              }
       }
       fetchData();
   },[]);

   const emptyArray = [
      { title:"Gallery Title"},
      { title:"Gallery Title"},
      { title:"Gallery Title"},
      { title:"Gallery Title"}

   ]
  var settings = { 
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false, 
    adaptiveHeight: true,
    responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll:1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll:1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow:1,
            slidesToScroll: 1, 
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  return (
     <> 
     <div className="row">
     <Slider {...settings}>
     {data && data.length > 0 ? (data.map((item,index) => ( 
      <div className="item" key={index}>
          <div className="gallerysec">
            <div className="gallerysectitle">
              <h3>{item.title}</h3>
            </div>
            <div className="gallerysecimg">
                <img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} className="img-fluid" alt="St. Basil School, Basti"/>
            </div>
          </div>
        </div> 

        ))) : (emptyArray.map((item,index) => (

          <div className="item" key={index}>
          <div className="gallerysec">
            <div className="gallerysectitle">
              <h3>{item.title}</h3>
            </div>
            <div className="gallerysecimg">
                <img src="https://webapi.entab.info/api/image/SBSB/public/Images/gal1.JPG" className="img-fluid" alt="St. Basil School, Basti"/>
            </div>
          </div>
        </div> 
        )))}
      </Slider>
     </div>
     </>
  )
}

export default Gallery