import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const RegistrationAdmission = () => {
  return (
     <>
       <Header/>
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>
            <li>  Admission </li>
            <li> Registration and Admission </li>
        </ul>
    </div>
    <div className="innersec">
      <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1> Registration and Admission </h1>
                </div>
            </div>
          <div className="row">
               <div className="col-lg-12">
                  <h5>Registration</h5>
                  <ul className="mainlist">
                  <li>  The Academic Year of the School is from April to March. </li>
                  <li> Admission is taken in Nursery/LKG. Admission in other classes will be entertained only at the instance of vacancy in that class, which will be announced in April/after new session starts. </li>
                  <li>  Prospectus and Registration Form will be available from the school office during the prescribed hours. </li>
                  <li>  Registration fee is Rs. 500 –<b> which is not refundable.</b> </li>
              
                  </ul>
                  <p><b>Registration does not Guarantee Admission. It will be subject to Merit and availability of Seats.</b></p>
                   
                  <h5>Requirements For Registration In Nursery & Lkg</h5>
                  <h5>  I. Age </h5>
<p><b> Nursery:- </b>  The child to be admitted in Nursery(Baby) class should have  
completed 3 years on 1 April of the year of Admission. </p>
<p><b>  LKG:- </b> The child to be registered shall have completed 4 years on 1 April of the Year of Admission. </p>
<h5>  II. 1. Birth Certificate:-  </h5>

<p>    a) An attested copy of the Date of Birth Certificate from the competent authority, that is, the Registrar of Birth and Death/Secretary in the Panchayat/Nagar Palika/Nagar Nigam should be submitted along with the Registration Form.  </p>
<p>    b) Baptism certificate from the Church must be produced in case of Christian children.  <br/> 
NB: i) Date of Birth once submitted shall not be altered at any stage later, on any account what so ever. <br/> 
ii) No other certificate will be accepted. <br/> 
iii) Applications without Birth Certificate will be rejected.  <br/> </p>
<p>    2. The child shall come accompanied by the parents for Registration and submission of Registration forms. </p>
<p>   3. Stamp size photographs should be affixed on the Registration form in the appropriate columns given. </p>
<p>    4. The interest and availability of the parents in helping the child will be counted in the selection of children for Admission. </p>
<h5> III. Interaction (for LKG) </h5>
<p>    i)  An interaction with the child will be done on the date informed. </p>
<p>    ii) The child brought for interaction shall be able to    <br/> 
• Recognize and recite English Alphabet. <br/> 
• Recognize and recite Hindi Alphabet. <br/> 
• Recognize and count numbers 1 to 20. <br/> 
• Recognize colours, flowers, animals, birds and fruits. <br/> 
• Answer questions smartly and intelligently. </p>
               </div>
          </div>
        </div>
    </div> 
       <Footer/>
     </>
  )
}

export default RegistrationAdmission
