import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/SBSB`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    const { data } = await axios.get(API_URL + `/api/news/SBSB`);
    console.log(data)
    return data;
} 

export const getNewsInner = async() => {
    const { data } = await axios.get(API_URL + `/api/news/All/SBSB`);
    console.log(data)
    return data;
}
export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/SBSB`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/SBSB`);
    console.log(data)
    return data;
}
 
 

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/SBSB`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/all/SBSB`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/SBSB`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/SBSB`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/SBSB`,getHeader());
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/SBSB`,getHeader());
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/SBSB`, getHeader());
    return data;
} 

  
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/SBSB`,{name,admission},getHeader());
    return data;
}
