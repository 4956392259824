import React from 'react'
import Header from'../Component/Header'
import HomeSlider from '../Component/HomeSlider'
import News from'../Component/News'
import Events from'../Component/Events'
import Facilities from'../Component/Facilities'
import TopperX from'../Component/TopperX'
import TopperXII from'../Component/TopperXII'
import Gallery from'../Component/Gallery'
import Message from'../Component/Message'
import Footer from'../Component/Footer'
import Highlights from '../Component/Highlights'
import { Link } from 'react-router-dom'
import OnlineReg from'../Component/OnlineReg'
import Notification from'../Component/Notification' 
import Pageloader from '../Component/PageLoader'
const Home = () => {
  return (
    <> 
    <Pageloader/>
    <Notification/>
    <OnlineReg/>
      <Highlights/>
      <Header/> 
      <div className="homeslider">
           <HomeSlider/>
         
      </div>
      <div className="rubylogo"><img src="Images/rubylogo.png" alt="" className="img-fluid"/> </div>

      <div className="aboutsec">
          <div className="aboutsecfst">
               <div className="aboutheading">
                   <img src="https://webapi.entab.info/api/image/SBSB/public/Images/about.gif" className="img-fluid"/>
                   <div> <h2>Mission & Vision</h2>
                   <p>Motto “LEAD KINDLY LIGHT”</p></div>
               </div>
               <div className="aboutdesc">
                  <p><strong>God, the eternal light, dispenses darkness of the mind through the radiance of His Knowledge.</strong> <br/>
                   St. Basil’s School aims at the integral development of the personality of every child; to make him committed to the service of God and of the nationa and instill in to him a spirit of compassion, unselfish services, dedication to duty, love for the fellow men and an unparalleled thrust for the excellence and wisdom.</p>
               
               </div>
               <div className="mainbtn">
                    <Link to="#"> Read More</Link>
               </div>
          </div>
          <div className="aboutsecscd">
               <div className="aboutsecscddesc">
                 {/*<div className="maintitle">
                    <h6>St. Basil’s School</h6>
                      <h1>Celebrating</h1>  
                </div> */}
                 <h5> St. Basil’s Basti  </h5>
                 <p>Celebrating <span>40  Years of </span><br/> Academic Excellence</p>
               </div>
           </div>
            <div className="aboutsecthrd">
               {/*<div className="mainbtn">
                      <Link to="#"> Admission Notice  <img src="https://webapi.entab.info/api/image/SBSB/public/Images/arrow.gif" className="img-fluid"/></Link>  
               </div>*/}
            </div>
      </div>
      <div className="aboutmidsec">
         <div className="container-fluid fulwidth">
               <div className="row">
                  <div className="col-lg-6">
                     <div className="maintitle">
                         <h6>St. Basil’s School</h6>
                         <h1>Our school</h1>
                    </div>
                      <div className="aboutmiddesc">
                      <p><strong>St. Basil's School, Basti, U.P.</strong> is an English Medium Institution, established in the Catholic Diocese of Gorakhpur, and administered by the Little Flower Mission Education Society, a registered charitable organization.The school was started on 02 January 1984, on the feast of St. Basil, the Patron Saint of Very Reverend Father Basilius CST, the Founder of Little Flower Congregation, the Parent society of the institution. The school is situated in the calm and serene atmosphere at Basil Nagar, village Khirighat, on the outskirts of city. St Basil’s School is affiliated to the Council for the Indian School Certificate Examinations, New Delhi. It prepares the students for the ICSE Examination at the end of class X, and ISC Examination at the end of class XII.</p>
                      <p>Being a minority institution, the school has the primary objective of educating the children of the community. However, we shall not spare any effort in rendering our service to all people, irrespective of caste and creed, so as to conserve their religion, language and culture. The school is established on the principle of equality and universal brotherhood.</p>
                      </div>
                      <div className="mainbtn">
                      <Link to="#"> Read More</Link>
                 </div>
                  </div>
                  <div className="col-lg-6">
                      <img src="https://webapi.entab.info/api/image/SBSB/public/Images/slide1.JPG" className="img-fluid aboutgf"/>
                  </div>
                </div>
         </div>
      </div>
      <div className="homenews">
            <div className="container">
                 <div className="row">
                      <div className="col-lg-12">
                         <div className="maintitle">
                                   <h6>School in News</h6>
                                   <h1>Latest News</h1>
                         </div>
                          <News/>
                      </div>     
                  </div>
             </div>
      </div>
      <div className="managementsec">
          <div className="managementsecbg">
             <div className="container fulwidth">
                <div className="row">
                 <div className="col-lg-12">
                    <div className="maintitle">
                              <h6>St. Basil’s School</h6>
                              <h1>st. basil management</h1>
                    </div>
                      <p><b>A creative Body with the principle of advancement to serve and develop the living standard of Basti Region  through education with the motto of “Being a Creator not competitor to inspire millions”</b></p>   
                    </div>   
                    </div>
               </div>
          </div>
          <div className="container">
               <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-6">
                        <div className="managementsecbox">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/management1.jpg" className="img-fluid"/>
                             <h5>Fr. Sabu Varkey Kandamkettiyil CST</h5>
                             <p>President of Little Flower Mission Education Society, Gorakhpur</p>
                        </div>
                  </div>
               <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="managementsecbox">
                       <img src="https://webapi.entab.info/api/image/SBSB/public/Images/management2.jpg" className="img-fluid"/>
                       <h5>Fr. Jojo Vadakkel CST</h5>
                       <p>Secretary – Education, Little Flower Mission Education Society, Gorakhpur </p>
                  </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="managementsecbox">
                 <img src="https://webapi.entab.info/api/image/SBSB/public/Images/management3.jpg" className="img-fluid"/>
                 <h5>Fr. Saji Paul CST</h5>
                 <p>Principal</p>
            </div>
      </div>
      <div className="col-xl-3 col-lg-3 col-md-6">
      <div className="managementsecbox">
           <img src="https://webapi.entab.info/api/image/SBSB/public/Images/management4.jpg" className="img-fluid"/>
           <h5>Fr. Binu Kottamurickal CST</h5>
           <p>Manager</p>
      </div>
</div>
          </div>
     </div>
     </div>
      <div className="homeevent">
          <div className="container">
               <div className="row">
                    <div className="col-lg-12">
                      <div className="maintitle">
                         <h6>Happenings in school</h6>
                         <h1>Events & Updates</h1>
                      </div>
                      <Events/>
                      <div className="mainbtn"><Link to="/Events"> View All</Link></div>
                    </div>
              </div>
          </div>
     </div>
     <div className="homemsg">
         <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                    <div className="maintitle">
                      <h6>from the desk of</h6>
                       <h1>Message section</h1>
                    </div>
                    <Message/>
                    
             </div>
             </div>
        </div>
      </div>
     <div className="facilities">
           <div className="container">
                <div className="row">
                     <div className="col-lg-12">
                        <div className="maintitle">
                            <h6>facilities in the school</h6>
                            <h1>Facilities provided</h1>
                        </div>
                         <div className="facilitiesfstbox">
                           <p>The scenario of education is being changed at a rapid pace. Modern education system is committed to make use of the advancement in technology and innovative methods to make the teaching learning process easy to the children. Moreover, we are leaving no stone unturned to make the system more child friendly and stress free.</p>
                           <p> ST. BASIL’S SCHOOL, BASTI provides the following facilities to maintain high standard in providing value education so that our students will surely be competent enough to withstand any competition in National level.</p>
                    
                         </div>
                    </div>
                </div>
           </div>
           <div className="container-fluid">
               <div className="row">
                       <Facilities/>  
                </div>
          </div>
     </div>
     <div className="toopersec">
         <div className="container">
             <div className="row">
               <div className="col-lg-12">
                  <div className="maintitle">
                      <h6>our shining stars</h6>
                      <h1>School Achievers</h1>
                  </div>
               </div>
            </div>
            </div>
           
          <div className="toopersecbg">
               <div className="container-fluid fulwidth">
                    <div className="row">
                         <div className="col-lg-6 col-md-6 topper12bx">
                              <img src="https://webapi.entab.info/api/image/SBSB/public/Images/classicn.png" className="img-fluid topperrib"/>
                         <TopperXII/>
                         <div className="mainbtn"><Link to="/TopperICS"> View All</Link></div>
                         </div>
                         <div className="col-lg-6 col-md-6 topper10bx">
                                        <img src="https://webapi.entab.info/api/image/SBSB/public/Images/classicn10.png" className="img-fluid topperrib"/>
                         <TopperX/>
                         <div className="mainbtn"><Link to="/TopperICSE"> View All</Link></div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
     <div className="homegallery">
       <div className="container-fluid fulwidth">
          <div className="row">
               <div className="col-lg-12">
                    <div className="maintitle">
                         <h6>school in media</h6>
                         <h1>School Gallery</h1>
                    </div>
                    <Gallery/>
                    <div className="mainbtn"><Link to="/Gallery"> View All</Link></div>
               </div>
          </div>
        </div>
     </div>
     <Footer/>
    </>
       
  )
}

export default Home
