import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const Admission = () => {

  return (
     <>
       <Header/>
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>
            <li> Admission </li>
            <li> Admission </li>
        </ul>
    </div>
    <div className="innersec">
    <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Admission </h1>
                </div>
            </div>
          <div className="row">
               <div className="col-lg-12">
                  <h5>Admission</h5>
                      <ul className="mainlist">
                      <li>  The names of the children selected for admission will be published on the School Notice Board on the date given. </li>
                      <li>  Admission of the selected children will be taken on the assigned days. </li>
                      <li>  Those who fail to respond shall forfeit their chance. </li>
                      <li> The first installment of fees shall be paid in the school office at the time of admission. </li>
                      <li>  Certificate required at the time of admission.  
                          <br/> i) Original Copy of the Date of Birth Certificate (for verification) 
                          <br/>    ii) Medical fitness certificates (in the Proforma given in the registration form)  
             
                          <br/>
                      </li>
                      <li>  Parents of the child must be present to fill the Admission Form and sign. No representative will be entertained. </li>
                      </ul>

                      <h5>Admission To Other Classes</h5>
                      <ul className="mainlist">
   <li>  Admission in classes other than LKG depends on the availability of seats, which shall be declared after the new session starts. Notice in this regard will be given by 24 April. </li>
<li>  Prospectus will be available from the school office for the classes where vacancies fall. </li>
<li>  Requirements for Registration:  <br/>
   i) An attested copy of the Birth Certificate (as in the case of LKG) <br/>
  ii)  Copy of the Report card from the school last attended which shows the student is promoted to the next class (for classes II and above) <br/>
  iii)  Character Certificate from the School last attended.  <br/>
</li>
<li>   i) For classes up to VI: <br/>
       Written test will be taken in Mathematics, Hindi, English and General Knowledge (which includes General Science and Social Studies) as per the syllabus of the school.
      ii) For other classes:- <br/>
       Written test in all subjects applicable for the classes. </li>
<li>   Registration and interaction will be taken on the prescribed day.  </li>
<li>    Names of the eligible students will be displayed on the Notice Board. </li>
<li>     Admission of the selected students will be taken on the fixed days. Those who fail to turn up will be deprived of the chance. </li>
<li>    Requirements at the time of Admission     <br/>
   i) Parents of the student to be admitted shall be present to fill the form and sign. (No representative will be accepted)  <br/>
ii) Certificates and Reports:  <br/>
  a) Transfer Certificate from the school last attended (for classes II and above) <br/>
  b) Date of Birth Certificate in original for verification.  <br/>
  c)  Medical Fitness Certificate (Proforma is given with the registration form) <br/>
  d) Two stamp size photographs. <br/> <br/>  
  <p><b>Admission will be taken on the basis of Merit Only.</b></p>
</li>

<li>   Admission in class IX/XI will be entertained only <br/>
i) in case of transfers from ICSE School <br/>
ii) if vacancy is there in the class <br/>
iii) if the student is found suitable for the class. <br/>
</li>

</ul>
               </div>
          </div>
    </div>
    </div>
       <Footer/>
     </>
  )
}

export default Admission
