import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const Curriculum = () => {
  return (
     <>
       <Header/>
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>
            <li> Academic </li>
            <li> Curriculum </li>
        </ul>
    </div>
    <div className="innersec">
    <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Curriculum </h1>
                </div>
            </div>
          <div className="row">
               <div className="col-lg-12">
                  <h5>Curriculum</h5>
                  <p><p>The course of study followed in the school is in accordance with the syllabus prescribed by the Council for the Indian School Certificate Examinations (CISCE), New Delhi in order to prepare the students for the ICSE(Class 10) and ISC(Class 12) Examinations.</p></p>
                  <h5>A. Kindergarten (LKG&UKG) Section: </h5>
                  <p> <b>English:-</b> Reading, Rhymes, Conversation, Dictation </p>
                  <p> <b>Hindi:-  </b>  Reading, Rhymes, Conversation , Dictation </p>
                  <p> <b> Arithmetic</b></p>
               <h5>B. Primary (Classes- I to V) Section: </h5>
                <p>English, Hindi, Mathematics, General Science, Social Studies(from class III), Moral Science and Value Education, Art and Craft, Computer Science (Classes III-V), Sanskrit(Class V), S.U.P.W(Socially Useful and Productive Works), General Knowledge.</p>
               <h5>C. High School (Classes – VI to X) Section:</h5>
                 <p><b> English-</b> Language and Literature</p>
                <p> <b> Hindi- </b> Language and Literature</p>
                 <p> Mathematics, Science(Physics, Chemistry & Biology), Sanskrit(Classes- VI to VIII), Social Science(Geography, History and Civics), Moral Science, General Knowledge, S.U.P.W(Socially Useful and Productive Works), Computer Science/Application. </p>
                 <h5>D. ISC (Classes - XI & XII) Section:</h5>
                  </div>
                  <div className="col-lg-4">
                  <p><b>Maths Group    </b></p>   
               <ul className="mainlist">
                  <li>  English           </li>
              <li>  Maths            </li>
              <li>  Physics          </li>
              <li>  Chemistry        </li>    
              <li>  Computer Sci.  </li>    
              <li>  S.U.P.W.        </li> 
              </ul>
              
              
              </div>
              <div className="col-lg-4">
                  <p> <b> Science Group     </b></p>
               <ul className="mainlist">
                  <li>  English </li>
              <li>  Physics </li>
              <li>  Chemistry </li>
              <li>  Biology </li>
              <li>  Hindi/Maths </li>
              <li>  S.U.P.W. </li>
              </ul>
              
              
              </div>
              <div className="col-lg-4">
                  <p> <b>Commerce Group </b></p>
               <ul className="mainlist">
                  <li>  English </li>
              <li>  Hindi/Maths </li>
              <li>  Accounts </li>
              <li>  Commerce </li>
              <li>  Economics </li>
              <li>  S.U.P.W. </li>
              </ul>
              </div>
          </div>
    </div>
    </div>
       <Footer/>
     </>
  )
}

export default Curriculum
